import React, { useState } from "react";
// import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
// import { RxDotFilled } from 'react-icons/rx';
import Slider from "react-slick";

function Herosection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  // const slides = [
  //   {
  //     url: 'https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2620&q=80',
  //   },
  //   {
  //     url: 'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80',
  //   },
  //   {
  //     url: 'https://images.unsplash.com/photo-1661961112951-f2bfd1f253ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80',
  //   },

  //   {
  //     url: 'https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80',
  //   },
  //   {
  //     url: 'https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80',
  //   },
  // ];

  // const [currentIndex, setCurrentIndex] = useState(0);

  // const prevSlide = () => {
  //   const isFirstSlide = currentIndex === 0;
  //   const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
  //   setCurrentIndex(newIndex);
  // };

  // const nextSlide = () => {
  //   const isLastSlide = currentIndex === slides.length - 1;
  //   const newIndex = isLastSlide ? 0 : currentIndex + 1;
  //   setCurrentIndex(newIndex);
  // };

  // const goToSlide = (slideIndex) => {
  //   setCurrentIndex(slideIndex);
  // };

  return (
    <div className="relative h-[calc(90vh-4rem)]">
      <Slider {...settings}>
        <div>
          <img
            src="/imgs/first.jpeg"
            alt="Slide 1"
            className="w-full h-[calc(90vh-4rem)] object-cover"
          />
        </div>
        <div>
          <img
            src="/imgs/second.JPG"
            alt="Slide 2"
            className="w-full h-[calc(90vh-4rem)] object-cover"
          />
        </div>
        <div>
          <img
            src="/imgs/third.jpg"
            alt="Slide 3"
            className="w-full h-[calc(90vh-4rem)] object-cover"
          />
        </div>
      </Slider>
      <div className="font-serif absolute inset-0 bg-black bg-opacity-50 flex items-center pl-10 lg:pl-96">
        <div className=" text-white px-4">
          <h1 className="text-5xl md:text-6xl font-bold mb-4">
            Crafting Visual Excellence
          </h1>
          {/* <p className="text-lg md:text-2xl pl-1">Crafting Visual Experience</p> */}
        </div>
      </div>
    </div>
    // <div className='max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 relative group'>
    //   <div
    //     style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
    //     className='w-full h-full rounded-2xl bg-center bg-cover duration-500'
    //   ></div>
    //   {/* Left Arrow */}
    //   <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
    //     <BsChevronCompactLeft onClick={prevSlide} size={30} />
    //   </div>
    //   {/* Right Arrow */}
    //   <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
    //     <BsChevronCompactRight onClick={nextSlide} size={30} />
    //   </div>
    //   <div className='flex top-4 justify-center py-2'>
    //     {slides.map((slide, slideIndex) => (
    //       <div
    //         key={slideIndex}
    //         onClick={() => goToSlide(slideIndex)}
    //         className='text-2xl cursor-pointer'
    //       >
    //         <RxDotFilled />
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
}

export default Herosection;
