// import React from "react";

// const Footer = () => {
//   return (
//     <footer class="p-4 bg-white sm:p-6 dark:bg-gray-800">
//       <div class="mx-auto max-w-screen-xl">
//         <div class="md:flex md:justify-between">
//           <div class="mb-6 md:mb-0">
//             <a href="/" class="flex items-center">
//               {/* class="mr-3 h-8" */}
//               <img
//                 src="/imgs/logo-removebg.png"
//                 className="h-14 mr-3 lg:h-20 lg:mr-0 lg:pr-5"
//                 alt="FlowBite Logo"
//               />
//               <span class="self-center text-3xl font-semibold whitespace-nowrap dark:text-white">
//                 Maekra
//               </span>
//             </a>
//           </div>
//           <div>
//             <h2 class="mb-2 text-sm font-semibold md:text-end text-gray-900 dark:text-white">
//               Mail : contact@maekra.com
//             </h2>
//             <h2 class="mb-2 text-sm font-semibold md:text-end text-gray-900 dark:text-white">
//               Tel - +91 9915188875
//             </h2>
//             <h2 class="mb-0 text-sm font-semibold md:text-end text-gray-900 dark:text-white">
//               Address : K&B Tower Cabin 1, 5th floor, Plot No. F-465,{" "}
//             </h2>
//             <h2 class="mb-2 text-sm font-semibold md:text-end text-gray-900 dark:text-white">
//               Industrial Area, Phase 8-B, S.A.S. Nagar{" "}
//             </h2>
//           </div>
//           {/* <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
//                 <div>
//                     <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
//                     <ul class="text-gray-600 dark:text-gray-400">
//                         <li class="mb-4">
//                             <a href="https://flowbite.com" class="hover:underline">Flowbite</a>
//                         </li>
//                         <li>
//                             <a href="https://tailwindcss.com/" class="hover:underline">Tailwind CSS</a>
//                         </li>
//                     </ul>
//                 </div>
//                 <div>
//                     <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
//                     <ul class="text-gray-600 dark:text-gray-400">
//                         <li class="mb-4">
//                             <a href="https://github.com/themesberg/flowbite" class="hover:underline ">Github</a>
//                         </li>
//                         <li>
//                             <a href="https://discord.gg/4eeurUVvTy" class="hover:underline">Discord</a>
//                         </li>
//                     </ul>
//                 </div>
//                 <div>
//                     <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
//                     <ul class="text-gray-600 dark:text-gray-400">
//                         <li class="mb-4">
//                             <a href="#" class="hover:underline">Privacy Policy</a>
//                         </li>
//                         <li>
//                             <a href="#" class="hover:underline">Terms &amp; Conditions</a>
//                         </li>
//                     </ul>
//                 </div>
//             </div> */}
//         </div>
//         <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
//         <div class="sm:flex sm:items-center sm:justify-between">
//           <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
//             © 2024{" "}
//             <a href="/" class="hover:underline">
//               Maekra
//             </a>
//             . All Rights Reserved.
//           </span>
//           <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
//             {/* WhatsApp */}
//             <a
//               href="https://wa.me/message/WWEN7YZLJVHKF1"
//               class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
//             >
//               <svg
//                 xmlns="https://wa.me/message/WWEN7YZLJVHKF1"
//                 class="w-5 h-5"
//                 fill="currentColor"
//                 viewBox="0 0 32 32"
//                 aria-hidden="true"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16 0C7.163 0 0 7.163 0 16c0 2.814.73 5.491 2.116 7.854l-1.38 5.02 5.162-1.352C10.501 29.259 13.199 30 16 30c8.837 0 16-7.163 16-16S24.837 0 16 0zm8.408 23.6c-.344.97-1.694 1.78-2.91 1.878-.768.066-1.712.129-5.732-2.45-4.806-2.982-7.935-8.588-8.16-8.982-.23-.398-1.943-2.664-1.943-5.086 0-2.423 1.215-3.626 1.644-4.118.43-.49.938-.608 1.256-.618.314-.008.664-.016 1.013.014.34.027.796-.132 1.245.956.444 1.084 1.513 3.774 1.646 4.04.132.263.132.462.024.663-.11.2-.168.433-.336.66-.168.223-.354.497-.504.668-.145.165-.296.348-.152.68.144.334.641 1.308 1.373 2.113.942 1.064 1.729 1.392 2.055 1.548.324.158.512.132.703-.082.19-.215.815-.98 1.033-1.32.218-.34.435-.28.733-.168.297.11 1.872.883 2.205 1.04.332.156.553.23.635.36.085.134.085.772-.26 1.742z"
//                   clip-rule="evenodd"
//                 />
//               </svg>
//             </a>

//             {/* linkdin */}
//             <a
//               href="https://www.linkedin.com/company/104595795/admin/dashboard/"
//               class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
//             >
//               <svg
//                 xmlns="https://www.linkedin.com/company/104595795/admin/dashboard/"
//                 class="w-5 h-5"
//                 fill="currentColor"
//                 viewBox="0 0 418 512"
//                 aria-hidden="true"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M100.28 448H7.4V148.9h92.88zm-46.44-340.8C24.9 107.2 0 82.7 0 52.3S24.9-2.6 53.84-2.6s53.92 24.4 53.92 54.9c.08 30.4-24.76 54.8-53.92 54.8zM447.9 448h-92.9V302.4c0-34.7-.7-79.3-48.34-79.3-48.36 0-55.8 37.8-55.8 76.8V448h-92.88V148.9h89.2v40.8h1.3c12.44-23.5 42.82-48.34 88.08-48.34 94.16 0 111.52 62 111.52 142.6V448z"
//                   clip-rule="evenodd"
//                 />
//               </svg>
//             </a>

//             {/* fb */}
//             <a
//               href="https://www.facebook.com/profile.php?id=61564490339517&is_tour_dismissed"
//               class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
//             >
//               <svg
//                 class="w-5 h-5"
//                 fill="currentColor"
//                 viewBox="0 0 24 24"
//                 aria-hidden="true"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
//                   clip-rule="evenodd"
//                 />
//               </svg>
//             </a>
//             {/* ig */}
//             <a
//               href="https://www.instagram.com/ma.ek.ra/"
//               class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
//             >
//               <svg
//                 class="w-5 h-5"
//                 fill="currentColor"
//                 viewBox="0 0 24 24"
//                 aria-hidden="true"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
//                   clip-rule="evenodd"
//                 />
//               </svg>
//             </a>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 bg-gray-800 sm:p-6">
      <div className="mx-auto max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex items-center">
              <img
                src="/imgs/logo-removebg.png"
                className="h-14 mr-3 lg:h-20 lg:mr-0 lg:pr-5"
                alt="FlowBite Logo"
              />
              <span className="self-center text-3xl font-semibold whitespace-nowrap text-white">
                Maekra
              </span>
            </a>
          </div>
          <div>
            <h2 className="mb-2 text-sm font-semibold md:text-end text-white">
              Mail : contact@maekra.com
            </h2>
            <h2 className="mb-2 text-sm font-semibold md:text-end text-white">
              Tel - +91 9915188875
            </h2>
            <h2 className="mb-0 text-sm font-semibold md:text-end text-white">
              Address : K&B Tower Cabin 1, 5th floor, Plot No. F-465,{" "}
            </h2>
            <h2 className="mb-2 text-sm font-semibold md:text-end text-white">
              Industrial Area, Phase 8-B, S.A.S. Nagar{" "}
            </h2>
          </div>
        </div>
        <hr className="my-6 border-gray-700 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-400 sm:text-center">
            © 2024{" "}
            <a href="/" className="hover:underline">
              Maekra
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            {/* WhatsApp */}
            <a
              href="https://wa.me/message/WWEN7YZLJVHKF1"
              className="text-gray-400 hover:text-white"
            >
              <svg
                xmlns="https://wa.me/message/WWEN7YZLJVHKF1"
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16 0C7.163 0 0 7.163 0 16c0 2.814.73 5.491 2.116 7.854l-1.38 5.02 5.162-1.352C10.501 29.259 13.199 30 16 30c8.837 0 16-7.163 16-16S24.837 0 16 0zm8.408 23.6c-.344.97-1.694 1.78-2.91 1.878-.768.066-1.712.129-5.732-2.45-4.806-2.982-7.935-8.588-8.16-8.982-.23-.398-1.943-2.664-1.943-5.086 0-2.423 1.215-3.626 1.644-4.118.43-.49.938-.608 1.256-.618.314-.008.664-.016 1.013.014.34.027.796-.132 1.245.956.444 1.084 1.513 3.774 1.646 4.04.132.263.132.462.024.663-.11.2-.168.433-.336.66-.168.223-.354.497-.504.668-.145.165-.296.348-.152.68.144.334.641 1.308 1.373 2.113.942 1.064 1.729 1.392 2.055 1.548.324.158.512.132.703-.082.19-.215.815-.98 1.033-1.32.218-.34.435-.28.733-.168.297.11 1.872.883 2.205 1.04.332.156.553.23.635.36.085.134.085.772-.26 1.742z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            {/* LinkedIn */}
            <a
              href="https://www.linkedin.com/company/104595795/admin/dashboard/"
              className="text-gray-400 hover:text-white"
            >
              <svg
                xmlns="https://www.linkedin.com/company/104595795/admin/dashboard/"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 418 512"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M100.28 448H7.4V148.9h92.88zm-46.44-340.8C24.9 107.2 0 82.7 0 52.3S24.9-2.6 53.84-2.6s53.92 24.4 53.92 54.9c.08 30.4-24.76 54.8-53.92 54.8zM447.9 448h-92.9V302.4c0-34.7-.7-79.3-48.34-79.3-48.36 0-55.8 37.8-55.8 76.8V448h-92.88V148.9h89.2v40.8h1.3c12.44-23.5 42.82-48.34 88.08-48.34 94.16 0 111.52 62 111.52 142.6V448z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            {/* Facebook */}
            <a
              href="https://www.facebook.com/profile.php?id=61564490339517&is_tour_dismissed"
              className="text-gray-400 hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            {/* Instagram */}
            <a
              href="https://www.instagram.com/ma.ek.ra/"
              className="text-gray-400 hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
