import React from "react";
import { Link } from "react-router-dom";
const Whatwedo = () => {
  return (
    <>
      <div className="lg:py-20 pt-20 pb-12">
        <h1 className=" text-center text-4xl lg:text-6xl py-3 lg:pt-20  lg:pb-12">
          What we do
        </h1>
        <p className=" text-center pt-1 lg:text-3xl pb-12 lg:mx-52 sm:px-10 md:px-20 lg:px-40 xl:px-56">
          At Maekra, we transform your brand vision into captivating visual
          experiences. Specializing in innovative and high-quality visual
          merchandising, we offer a wide range of solutions designed to make
          your brand shine.
        </p>
        <h3 className="text-center pt-4 lg:pt-8 pb-4 text-3xl lg:text-4xl">
          Our Expertise
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-16 p-4 lg:px-16">
          <a
            href="/Products"
            className="block max-w-sm px-6 py-10 border  rounded-lg shadow  bg-gray-800 border-gray-700 hover:bg-gray-700 mx-auto my-5"
          >
            <h5 className="mb-2 text-4xl md:text-2xl font-bold tracking-tight text-white">
              Elevate Your Brand
            </h5>
            <p className=" text-gray-400 text-md md:text-xl">
              From dynamic in-shop displays to vibrant LED signages, we craft
              customized branding solutions that elevate your presence and
              engage your audience.
            </p>
          </a>
          <a
            href="/Products"
            className="block max-w-sm px-6 py-10  border rounded-lg shadow  bg-gray-800 border-gray-700 hover:bg-gray-700 mx-auto my-5"
          >
            <h5 className="mb-2 text-4xl md:text-2xl font-bold tracking-tight  text-white">
              Illuminate and Inspire
            </h5>
            <p className=" text-gray-400 text-md md:text-xl">
              Our backlit and neon signs are not just about visibility; they’re
              about creating unforgettable impressions. Light up your brand with
              designs that inspire and captivate.
            </p>
          </a>
          <a
            href="/Products"
            className="block max-w-sm px-6 py-10  border  rounded-lg shadow  bg-gray-800 border-gray-700 hover:bg-gray-700 mx-auto my-5"
          >
            <h5 className="mb-2 text-4xl md:text-2xl font-bold tracking-tight  text-white">
              Eco-Friendly Options
            </h5>
            <p className=" text-gray-400 text-md md:text-xl">
              Sustainability meets style with our eco-friendly fabric signages.
              Showcase your commitment to the environment without compromising
              on impact.
            </p>
          </a>
          <a
            href="/Products"
            className="block max-w-sm px-6 py-10 border  rounded-lg shadow  bg-gray-800 border-gray-700 hover:bg-gray-700 mx-auto my-5"
          >
            <h5 className="mb-2 text-4xl md:text-2xl font-bold tracking-tight text-white">
              Innovative Advertising
            </h5>
            <p className=" text-gray-400 text-md md:text-xl">
              Transform ordinary spaces into extraordinary brand experiences
              with our ambient advertising solutions. Make your mark in
              unexpected places.
            </p>
          </a>
          <a
            href="/Products"
            className="block max-w-sm px-6 py-10 border rounded-lg shadow bg-gray-800 border-gray-700 hover:bg-gray-700 mx-auto my-5"
          >
            <h5 className="mb-2 text-4xl md:text-2xl font-bold tracking-tight text-white">
              Striking Visuals
            </h5>
            <p className=" text-gray-400 text-md md:text-xl">
              From stunning window setups to custom wall graffiti prints, our
              visual solutions are designed to attract attention and leave a
              lasting impression.
            </p>
          </a>
        </div>
      </div>

      <div className="flex justify-center">
        <Link to="/Products">
          <button
            type="button"
            className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-lg px-16 py-4 text-center me-2 mb-2"
          >
            Gallery
          </button>
        </Link>
      </div>
    </>
  );
};
export default Whatwedo;
